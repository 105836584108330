import React, {useState} from 'react'
import { send } from 'emailjs-com';
import toast from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import { languageState } from '../recoil/language/languageState';


function Subscribe() {


  const l = useRecoilValue(languageState)

  const [toSend, setToSend] = useState({
    from_name: '',
    message: '',
    reply_to: '',
  });

  const onSubmit = (e) => {
    
    e.preventDefault();
    send(
      'service_95zq2v7',
      'template_w8pmizq',
      toSend,
      'vNYZIOKG9h6SX79GA'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast.success(l.toast.message_sent)
        
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
    setToSend({
      from_name: '',
      message: '',
      reply_to: '',
    })
    toast(l.toast.sending_message, {
      icon: '👏',
    });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };


  return (
    <div id='subscribe'>
        <h3>{l.contact.hero}</h3>
        <form  className='subscribe-form'onSubmit={onSubmit}>
        <div className='subscribe-input'>
            <input 
              type='text'  
              placeholder={l.contact.name}
              name='from_name'
              value={toSend.from_name}
              onChange={handleChange}
            />
           
        </div>
        <div className='subscribe-input'>
        <input 
              type='email'  
              placeholder={l.contact.email}
              name='reply_to'
              value={toSend.reply_to}
              onChange={handleChange}
            />
           
        </div>
        <div className='subscribe-input-area'>
        <input 
              type='text'  
              placeholder={l.contact.message}
              name='message'
              value={toSend.message}
              onChange={handleChange}
            />
           
        </div>
        <div className='subscribe-input2'>
            {/* <input type='email' placeholder='example@gmail.com'/> */}
            <button type='submit'>{l.contact.continue}</button>
        </div>
        </form>
    </div>
  )
}

export default Subscribe