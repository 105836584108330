import { atom, selector } from 'recoil';
import ee from '../../constants/languages/ee';
import en from '../../constants/languages/en';
import ru from '../../constants/languages/ru';

export const langState = atom({
  key: 'lang',
  default: 'ee',
});

export const languageState = selector({
  key: 'languageState',
  get: ({ get }) => {
    const lang = get(langState);
    switch (lang) {
      case 'ee':
        return ee;
      case 'en':
        return en;
      case 'ru':
        return ru;
      default:
        return ee;
    }
  },
  set: ({ set, get }, payload) => {
    set(langState, prevLang => {
      if (['ee', 'en', 'ru'].includes(payload) && payload !== prevLang) {
        return payload;
      } else {
        console.log('invalid language choice');
        return prevLang; // Ensure we return the previous language if the payload is invalid
      }
    });
  },
});
