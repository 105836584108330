const privacyText = `1. Contact Details 
Unless otherwise specified for a specific market or service, “Manager” or “Controller” within the meaning of the General Data Protection Regulation (hereinafter “GDPR” or “EU-GDPR”), other data protection laws applicable in the member states of the European Union and other provisions with a data protection nature is Bloom Group OÜ, Tõnismägi 11a - 103, 10119 Tallinn, Estonia, E-Mail: info@bloom.ee, represented by Leonard-Ethan Vellend (“Bloom”, “we” or “us”). 
The data protection officer of Bloom can be reached at the above-mentioned address, Data Protection Officer, or via a coordinator at alfred@bloom.ee. 
2. Information We Collect 
The Personal Data we collect from you depends on your specific relationship with us. Below we describe what kind of data we collect, where it comes from, the purpose and the legal basis of the processing, its duration, and the recipients with whom the data is shared. One or several of the following specific situations may apply to you: 
2.1 Processing when using our Services 
a) Data concerned 
When accessing our website, ourselves or our web hosting provider, collect data on the basis of each access to the server (so-called server log files). Server log files may include the following information: 
● the browser types and versions used; 
● the operating system used by the accessing system; 
● the date and time of an access; 
● the pages of our service that you visit; 
● referrer URL (the previously visited page); 
● the Internet Protocol (IP) address of your device; 
When you access the Service by or through a mobile device or App, we may collect the following information automatically, 
● the type of mobile device you use; 
● your mobile device unique ID; 
● the IP address of your mobile device; 
● your mobile operating system; 
● the type of mobile internet browser; 
● unique device identifiers and other diagnostic data. 
Processing of location data: Within the course of using our App, the location data as collected by the device or as otherwise entered by the user are processed. The use of the
location data serves only to provide the respective functionality of our App, according to its description to the users or its typical and expectable functionality. 
b) The purpose of processing and legal basis 
We use the collected data (the type of mobile device you use,your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile internet browser, 
unique device identifiers and other diagnostic data) to provide contractual services and customer support, deliver and optimize the content of our Services correctly and to ensure the long-term viability and technical security of our systems. This purpose constitutes our legitimate interest in data processing pursuant to art. 6 para. 1 lit. f GDPR. 
We use the information regarding your location to provide and maintain our Service and to provide features of our Service. As a part of our Services we deliver products quickly based on the location of a customer, therefore it is necessary to access location data. The legal basis for such processing is art. 6 para. 1 lit. f GDPR. 
Personal data that you voluntarily submit to us, e.g. via email or a contact form, will be stored for the purpose of processing or for contacting you. The legal basis for such processing is art. 6 para. 1 lit. f GDPR. 
c) Sharing your data 
We will share your data with third parties who store your data on their servers. The types of third parties with whom we share your data include: 
● IT service providers: including cloud providers for data storage purposes; ● External support service providers; 
● Public bodies and authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of art. 6 para 1 lit. c GDPR. 
d) Duration of storage 
We store your personal data only as long as it is necessary to achieve the purpose of processing. If we use your personal data on the basis of a legitimate interest, we will store it at most as long as your interest in deletion or anonymisation does not prevail. In addition, data may be stored if this has been provided for by the European Union or national legislator in regulations, laws or other provisions to which the person responsible is subject. The duration of the data storage depends on the statutory storage obligations and is generally in Estonia is 10 years
2.2 Cookies 
Cookies are text files which are stored on a computer system via an internet browser. Cookies are primarily used to store information about a user during or after his visit within an online service. The information stored can include, for example, the language settings on a website, the login status or a shopping basket. The term "cookies" hereinafter also includes other technologies that fulfill the same functions as cookies (e.g. if user information is stored using online identifiers, also referred to as "user IDs"). 
a) The following types and functions of cookies are distinguished: 
● Temporary cookies (also: session cookies): Temporary cookies are deleted at the latest after a user has left an online service and closed his browser. 
● Permanent cookies: Permanent cookies remain stored even after closing the browser. For example, the login status can be saved or preferred content can be displayed directly when the user visits a website again. The interests of users who are used for range measurement or marketing purposes can also be stored in such a cookie. 
● First-Party-Cookies: First-Party-Cookies are set by ourselves. 
● Third party cookies: Third party cookies are mainly used by advertisers (so-called third parties) to process user information. 
● Necessary (also: essential) cookies: Cookies can be necessary for the operation of a website (e.g. to save logins or other user inputs or for security reasons). ● Statistics, marketing and personalisation cookies: Cookies are also generally used to measure a website's reach and when a user's interests or behavior (e.g. viewing certain content, using functions, etc.) are stored on individual websites in a user profile. Such profiles are used, for example, to display content to users that corresponds to their potential interests. This procedure is also referred to as "tracking", i.e. tracking the potential interests of users. 
b) Legal basis for the use of cookies 
The legal basis on which we process your Personal Data with the help of cookies depends on whether we ask you for your consent. If this applies and you consent to the use of cookies, the legal basis for processing your data is your declared consent according to art. 6 para. 1 lit. a GDPR or art. 9 para. 2 lit. a GDPR. 
Otherwise, the data processed with the help of cookies will be processed on the basis of our legitimate interests according to art. 6 para. 1 lit. f GDPR (e.g. in a business operation of our online service and its improvement) or, if the use of cookies is necessary to fulfill our contractual obligations according to art. 6 para. 1 lit. b GDPR.
c) Duration of storage 
Unless we provide you with explicit information on the retention period of permanent cookies (e.g. within the scope of a so-called cookie opt-in), the retention period is as long as it is necessary to achieve the purpose of the processing. 
d) General information on withdrawal of consent and objection (Opt-Out) 
Respective of whether processing is based on consent or another legal basis, you have the option at any time to object to the processing of your data using cookie technologies or to revoke consent (collectively referred to as "opt-out"). You can initially explain your objection using the settings of your browser, e.g. by deactivating the use of cookies (which may also restrict the functionality of our Services). 
e) Processing Cookie Data on the Basis of Consent 
We use a cookie management solution in which users' consent to the use of cookies, or the procedures and providers mentioned in the cookie management solution, can be obtained, managed and revoked by the users. The declaration of consent is stored so that it does not have to be retrieved again and the consent can be proven in accordance with the legal obligation. Storage can take place server-sided and/or in a cookie (so-called opt-out cookie or with the aid of comparable technologies) in order to be able to assign the consent to a user or and/or his/her device. 
2.3 Processing of customer/ prospective customer data 
a) Data concerned 
When you create a customer or user account via our Services or if you engage with us in associated contractual or pre-contractual actions and communications we will collect the following data from you: 
● Inventory data (e.g. name, addresses); 
● Contact data (e.g. e-mail, telephone numbers); 
● Delivery and invoice address; 
● Payment data (e.g. credit card, paypal, invoices, payment history, etc); ● App usage data (e.g. products clicked, viewed, added and removed, collection and favorites viewed, reorder and coupons clicked, banner viewed, referral banner clicked, basket value, promo code applied, score given, etc) 
● Login data (e.g. phone number, Facebook, Google or Apple) 
● Order history (e.g. date, products, discounts) 
● Meta/communication data (e.g. device information, IP address).
b) The purpose of processing and legal basis 
We process the data of our customers or prospective customers in order to enable them to select, purchase or order the selected products, goods and related services, as well as their payment, for delivery, performance, marketing or other services. 
We process app usage data, contact data and inventory data such as name, address, email address, products clicked, viewed, added and coupon information for fraud detection,improving the customer experience and app quality and rely on Article 6 para. 1 lit. f GDPR which is a legitimate interest of our company. 
We may use your contact details for email marketing and obtain your consent for such emails (article 6 para. 1 lit. a GDPR). 
For the processing of payment transactions we use the services of banks and payment service providers. The required details are identified as such in the course of the ordering or comparable purchasing process and include the details required for delivery, or other way of making the product available and invoicing as well as contact information in order to be able to hold any consultation. 
Legal basis for this is** **performance of a contract and prior requests (article 6 para. 1 lit. b GDPR). We may use some of the referred data to comply with legal obligations (article 6 para. 1 lit c GDPR) such as tax law, accounting, or the collaboration with law enforcement. 
c) Sharing your data 
We will share your data with third parties in order to provide our Services. The types of third parties with whom we share your data are: 
● External service providers or other contractors, e.g. for data processing and hosting, for order processing and execution, payment providers, fraud detection, marketing, feedback and survey providers, customer service and call centers; 
● Other external bodies, provided that the data subject has given his or her consent or if transmission is permitted for reasons of overriding legitimate interest, e.g. for the electronic transmission of information or for quality assurance purposes; 
● Public bodies or authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of Art. 6 para. 1 lit. c GDPR. 
All information that you provide to payment service providers in the context of payment processing will not be passed on to us by them. We only receive the information that a payment transaction has been successful.
d) Duration of storage 
We store your personal data only as long as it is necessary to achieve the purpose of processing. Some data may only be deleted after the expiry of legal retention periods. Depending on the specific data sets concerned the retention periods may vary. As a rule data for tax purposes in Estonia is kept for 7 years. 
If you delete your customer account, your data will be deleted with regard to the customer account as long as the data does not need to be kept due to legal obligations. In these cases, the data is deleted when a storage or retention period expires. 
e) Single Sign-on Authentication 
“Single Sign-On" or "Single Sign-On Authentication or Logon" are procedures that allow users to log in to our online services using a user account with a provider of Single Sign-On services (e.g. a social network). The prerequisite for Single Sign-On Authentication is that users are registered with the respective Single Sign-On provider and enter the required access data in the online form provided for this purpose, or are already logged in with the Single Sign-On provider and confirm the Single Sign-On login via the button. 
Authentication takes place directly with the respective single sign-on provider. Within the scope of such authentication, we receive a user ID with the information that the user is logged in with the respective single sign-on provider under this user ID and an ID that cannot be used for other purposes (so-called "user handle"). Whether we receive further data depends solely on the single sign-on procedure used, the data releases selected as part of authentication and also which data users have released in the privacy or other settings of the user account with the single sign-on provider. Depending on the single sign-on provider and the user's choice, there can be different data, usually the e-mail address and the user name. The password entered by the single sign-on provider as part of the single sign-on procedure is neither visible to us nor is it stored by us. 
Users are requested to note that their data stored with us can be automatically compared with their user account with the single sign-on provider, but this is not always possible or actual. If, for example, the e-mail addresses of users change, users must change these manually in their user account with us. 
We can use single sign-on authentication and the related data processing, on the basis of our legitimate interests (art. 6 para. 1 lit. f GDPR) in an effective and secure authentication system, as the users voluntarily choose to use such a system and expect a certain information exchange between the Single Sign-On provider and us. 
Should users decide to no longer want to use the link of their user account with the Single Sign-On provider for the Single Sign-On procedure, they must remove this link within their user account with the Single Sign-On provider.
2.4 Processing of rider data 
a) Data concerned 
We collect your data in various ways, such as when you apply for a job at Bloom (see section 2.5 below), when you become an employee of Bloom (see section 2.6 below) and when you provide Services as delivery riders for Bloom. The following data may be collected when you are providing delivery services: 
● full name, telephone number; 
● location; 
● transaction information in connection with the delivery; 
● verification date and time of the rider on the rider app, 
● the browser types and versions used; 
● the operating system used by the accessing system; 
● user ID in the rider app, 
● image(optional) 
● vehicle information, 
● employee ID, 
● start and end location of the delivery (warehouse), 
● date and time of the last order, 
● the date and times of access. 
b) GPS/ Location 
When you are executing delivery orders for Bloom our Services require that your location is tracked in real time via the mobile application you are using. The legal basis for this is the legitimate interest of our company. (Art. 6 para. 1 lit. f GDPR.) 
c) The purpose of processing and legal basis 
We collect your data (e.g. location) for providing our customers a live status of their delivery, to document executed deliveries and to determine the time of arrival of a delivery and rely on the legitimate interest of our company. (Art. 6 para. 1 lit. f GDPR.) 
Furthermore we use this data (e.g. the browser types and versions used, the operating system used by the accessing system, user ID in the rider app, image(optional), vehicle information, employee ID, start and end location of the delivery (warehouse), date and time of the last order, the date and times of access.) for quality assurance, service-related communication and to provide, personalize, maintain and improve our products and Services. We also use this data to document the time you are working for Bloom. The legal basis for this is art. 6 para. 1 lit. f GDPR.
d) Sharing your data 
We will share your data with third parties who store your data on their servers or on their devices. The types of third parties with whom we share your data are: 
● Our customers and clients in connection with our products and Services; ● External service providers or other contractors (e.g. for data processing and hosting, for order processing and execution, payroll accounting, HR software providers, training providers, for feedback and survey providers and call centers); ● Public bodies or authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of art. 6 para. 1 c GDPR. 
e) Duration of storage 
We store your personal data only as long as it is necessary to achieve the purpose of processing. Some data is subject to legal retention periods. This data is deleted after the retention period expires. Generally a common retention period in Estonia is 10 years. 
2.5 Processing of data in the application process 
a) Data concerned 
Personal data that you provide us with during the application process. This may include the following data: Personal details (name, address, contact details, date and place of birth and nationality), application documents (letter of application, curriculum vitae, references, certificates, etc.). 
As part of the application process, you will have to provide us with the Personal Data that enables us to assess the possibility of entering into a working relationship with you or which we are legally obliged to collect. Without this data we will generally not be able to conclude a contract with you or process your application. 
b) The purpose of processing and legal basis 
Conducting the application process. The legal basis for this is entering into a contract (Art. 6 para. 1 b.) We may store your application data for some time to defend ourselves against legal claims. The legal basis for this is our legitimate interest (art. 6 para. 1 lit. f GDPR). If you consent (art. 6 para. 1 lit. a GDPR or art. 9 para. 2 lit. a GDPR) we may store your information in our applicant pool.
c) Sharing your data 
We will share your data with third parties who store your data on their servers or devices. The types of third parties with whom we share your data are: 
● External service providers or other contractors (e.g. for data processing and hosting, for processing your application, recruitment agencies and recruitment software); ● Public bodies or authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of Art. 6 para 1 c GDPR. 
d) Duration of storage 
Application data will be deleted within a maximum of six months if the application process does not lead to an employment relationship, unless you have given consent for longer data storage in the context of the inclusion of your data in our applicant pool or if another legal basis is in place. 
2.6 Processing of employee data 
a) Data concerned 
We process Personal Data that we receive from our employees and other similar data subjects as part of the onboarding process and the working relationship. These Personal Data include in particular: 
● Your personal and contact details (e.g. name, address, contact details, date and place of birth and nationality); 
● Family data (e.g. marital status and details of children); 
● Information about your working permit (nationality, passport details, identity card details, national insurance number and details of residence or work permit); ● Religious affiliation, gender, health data, trade union membership (where permitted by law); 
● Information on qualifications, data on staff development and staff evaluation (e.g. education, work experience, training, promotions, details of payment and special compensation disciplinary measures, performance and appraisal details); 
● Details of the employment relationship (e.g. date of entry, job title and description); ● Payroll and tax relevant data (e.g. salary payment); 
● Job application (e.g. education, area of expertise and previous experience) ● Information on working time (e.g. holidays, illness and data relating to business trips); ● Information on your use of IT systems 
● portrait picture (optional) 
as well as other data comparable with the above categories.
b) The purpose of processing and legal basis 
Data will be processed for the purpose of establishing, carrying out and terminating of employment relationships or for the purpose of carrying out pre-contractual measures which are conducted on request. The processing is necessary for a performance of the employment contract and the legal basis for this type of processing is art. 6 para. 1 lit. b GDPR and art. 88 GDPR in conjunction with § 2 of Isikuandmete Kaitse Seadus (Estonian Data Protection Act) or other applicable national laws. 
To the extent necessary, we process your data beyond the actual fulfillment of our contract where we have a legitimate interest in doing so. The legal basis for such processing activities is art. 6 para.1 lit. f GDPR. Examples for such processing activities are: 
● Measures for personnel development planning; 
● Measures to protect employees and customers and to protect the property of the company; 
● Evaluation of workflows for work control and improvement of processes; ● Publication of official contact details on the intranet; 
● Written records of appraisal interviews. 
If you have given us your consent to process your Personal Data, we will process your data only accordance with and to the extent agreed in the declaration of consent. A given consent can be revoked at any time with effect for the future. The revocation of the consent does not affect the legality of the data processed until the revocation. The legal basis for processing on the basis of your consent is art. 6 para. 1 lit. a GDPR and art. 88 GDPR in conjunction with § 2 of Isikuandmete Kaitse Seadus or other applicable national laws. 
As a company we are subject to various legal obligations (e.g. social security law, work safety regulations, tax laws). Processing on the basis of such legal requirements is done, among other things, to verify identity, to fulfill social security and tax law control, reporting or documentation obligations and to manage risks within the company. These type of processing activities are necessary for the compliance with the legal obligations and the legal basis for this is art. 6 para. 1 lit. c GDPR. 
Insofar as special categories of Personal Data are processed in accordance with art. 9 Para. 1 GDPR, this serves the exercise of rights or the fulfillment of legal obligations arising from labor law, social security law and social protection (e.g. provision of health data to the health insurance funds, recording of severe disability due to additional leave and calculation of the severely disabled levy). Such processing is carried out on the basis of art. 88 GDPR in conjunction with § 2 of Isikuandmete Kaitse Seadus or other applicable national laws. 
c) Sharing your data
We will share your data with third parties who store your data on their servers. The types of third parties with whom we share your data include: 
● External service providers or other contractors (e.g. for data processing and hosting, payroll accounting, HR software providers, training providers); 
● Public bodies or authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of art. 6 para. 1 lit. c GDPR. 
d) Duration of storage 
We store your personal data only as long as it is necessary to achieve the purpose of processing. Some data is subject to legal retention periods. This data is deleted after the retention period expires. Generally a common retention period in Estonia is 7 years for tax purposes. Other retention periods may be shorter; very few are longer. 
2.7 Processing of supplier data 
a) Data concerned 
Data communicated for the execution of the contract, as well as any additional data for processing on the basis of your express consent. 
● Contact details (e.g. full name, email, phone number) 
● Payment details( e.g. bank information, transaction data) 
b) The purpose of processing and legal basis 
Contract execution, including enquiries, purchasing, quality assurance on the basis of art. 6 para. 1 lit. b GDPR. In some cases where we ask for your consent the legal basis is art. 6 para. 1 lit. a GDPR. 
c) Sharing your data 
We will share your data with third parties who store your data on their servers. The types of third parties with whom we share your data include: 
● External service providers or other contractors, e.g. for data processing and hosting, bookkeeping, payment processing; 
● Other external parties provided that the data subject was duly informed. ● Public bodies or authorities if the appropriate legal provisions exist (e.g. tax authorities and customs authorities) on the basis of art. 6 para. 1 lit. c GDPR.
d) Duration of storage 
We store your personal data only as long as it is necessary to achieve the purpose of processing. Some data is subject to legal retention periods. This data is deleted after the retention period expires. Generally a common retention period in Estonia is 10 years (20 years in underage death cases according to § 9 (1) (2) ). Other retention periods may be shorter. 
2.8 Conducting telephone/video conferences/ screen-sharing 
a) Data concerned 
Data provided for the use of telephone/video conference software (in particular first name, surname, e-mail address; optional: sound transmission; optional: image transmission; optional: questions when using chat functions); to the extent technically necessary, processing of data from your system to establish the connection with the provider of the conference software. 
b) The purpose of processing and legal basis 
Conducting telephone/video conferences/ screen-sharing; the legal basis in this case is our legitimate interest (art. 6 para. 1 lit. f GDPR) to provide a working communication tool. Using the recorded telephone/video conferences for training and quality assurance purposes (only if prior consent has been given); the legal basis for this is Art. 6 para 1 lit. a. 
c) Sharing your data 
We will share your data with third parties who store your data on their servers. The types of third parties with whom we share your data include: 
● External service providers or other contractors, e.g. for data processing, video conferences and hosting. 
● Other external parties, provided that the data subject was duly informed ● Public bodies and authorities in the event of overriding legal provisions. 
d) Duration of storage 
Video conferences will only be recorded with the prior documented consent of the participants. We will store video conferences only as long as it is necessary to achieve the agreed purpose of processing. The technical data will be deleted if they are no longer required. The duration of data storage is otherwise governed by the statutory storage obligations and is generally 10 years.
2.9 Social Media 
We maintain online presences within social networks and process user data in this context in order to communicate with the active users or offer information about us. 
When you visit or interact with a profile on a social media platform, Personal Data about you may be processed. Also information associated with a social media profile regularly represent Personal Data. This also includes messages and statements made using the profile. In addition, during your visit to a social media profile, certain information is often automatically collected, which may also represent Personal Data. 
a) Data concerned 
Inventory data (e.g. names, addresses), contact data (e.g. e-mail, telephone numbers), content data (e.g. entries in online forms), usage data (e.g. websites visited, interest in content, access times), meta/communication data (e.g. device information, IP addresses). 
b) The purpose of processing and legal basis 
Contact requests and communication, tracking (e.g. interest/behavioral profiling, use of cookies), remarketing, reach measurement (e.g. access statistics, recognition of returning visitors). The legal basis for this is Art. 6 (1) lit. f GDPR. 
c) Sharing your Data 
For a detailed presentation of the respective forms of processing and the possibilities of objection (opt-out), we refer to the data protection policies and information provided by the operators of the respective networks. 
Also in the case of requests for information and the assertion of data subject rights, we advise that these can most effectively be asserted with the providers. Only the providers have access to the data of the users and can take appropriate measures and provide information directly. 
Facebook: We are jointly responsible (so called "joint controller") with Facebook Ireland Ltd. for the collection (but not the further processing) of data of visitors to our Facebook page. This data includes information about the types of content users view or interact with, or the actions they take (see "Things that you and others do and provide" in the Facebook Data Policy: https://www.facebook.com/policy), and information about the devices used by users (e.g., IP addresses, operating system, browser type, language settings, cookie information; see "Device Information" in the Facebook Data Policy: https://www.facebook.com/policy). As explained in the Facebook Data Policy under "How we use this information?" Facebook also collects and uses information to provide analytics services, known as "page insights," to site operators to help them understand how people interact with their pages and with content associated with them. We have concluded a special agreement with Facebook ("Information about Page-Insights", https://www.facebook.com/legal/terms/page_controller_addendum),
which regulates in particular the security measures that Facebook must observe and in which Facebook has agreed to fulfill the rights of the persons concerned (i.e. users can send information access or deletion requests directly to Facebook). The rights of users (in particular to access to information, erasure, objection and complaint to the competent supervisory authority) are not restricted by the agreements with Facebook. Further information can be found in the "Information about Page Insights" (https://www.facebook.com/legal/terms/information_about_page_insights_data). 
● Processed data types: Contact data (e.g. e-mail, telephone numbers), Content data (e.g. text input, photographs, videos), Usage data (e.g. websites visited, interest in content, access times), Meta/communication data (e.g. device information, IP addresses). 
● Data subjects: Users (e.g. website visitors, users of online services). ● Purposes of Processing: Contact requests and communication, Feedback (e.g. collecting feedback via online form), Marketing. 
● Legal Basis: Legitimate Interests (Article 6 (1) (f) GDPR). 
Service providers being used: 
● Instagram: Social network; Service provider: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA, , Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website: https://www.instagram.com; Privacy Policy: https://instagram.com/about/legal/privacy. 
● Facebook: Social network; Service provider: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, parent company: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website: https://www.facebook.com; Privacy Policy: https://www.facebook.com/about/privacy; Opt-Out: Settings for advertisements: https://www.facebook.com/settings?tab=ads. 
● LinkedIn: Social network; Service provider: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland; Website: https://www.linkedin.com; Privacy Policy: https://www.linkedin.com/legal/privacy-policy; Opt-Out: https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
3. Data Transmission Within The Companies, Or/And Joint Venture 
a) We may transfer Personal Data to other companies within our group of companies, namely Bloom Group OÜ & Darkstore OÜ or otherwise grant them access to this data. Insofar as this disclosure is for administrative purposes, the disclosure of the data is based on our legitimate business and economic interests or otherwise, if it is necessary to fulfill our contractual obligations or if the consent of the data subjects or otherwise a legal basis is present. We have entered into Internal Data Transfer Agreements (“IGTA”) to transfer personal data within our group of companies in a compliant and secure way. For more information about our IGTA and receiving a copy of the agreement, please reach out to alfred@bloom.ee. 
b) We only share your data with our personnel if this is necessary for the purposes described above. 
c) We may also share your data if we should enter into a joint venture, buy, sell or merge with another company. In such a case, your data may be shared with the target company, our new business partners or owners or their advisors. 
4. International Data Transmission 
The service providers and business partners are carefully selected by Bloom with particular regard to the suitability of the technical and organizational measures taken by them and checked for their compliance. Bloom processes the data in Estonia as a matter of principle. However, it is possible that your data may be transferred to processors in a country outside the European Union or the European Economic Area(“EEA”) (so-called "third country").These countries may not have the same level of data protection as the EEA. However, we are obliged to ensure that the Personal Data processed by us and our partners outside the EEA are protected in the same way as if they were processed within the EEA. Therefore, if your data is processed outside the EEA, there are certain safeguards in place. 
In this case, Bloom will provide suitable guarantees to ensure that the respective service providers protect the data appropriately, such as entering into the EU model clauses with the recipient of such data. We ensure similar protection by ensuring that at least one of the following safeguards is in place: 
● Your Personal Data will be transferred to countries whose level of data protection is considered appropriate by the European Commission according to art. 45 GDPR; ● We use the standard contractual clauses approved by the EU and carry out Transfer 
Impact Assessment for each personal data transfer outside the EEA and assess whether the laws and practices of the third country offer a level of protection substantially equivalent to that of the EEA; 
● An exception as set out in art. 49 GDPR; 
● Other safeguards as specified in art. 46 GDPR.
Your personal data may be processes in the United States of America (US), United Kingdom (UK) and Israel. For personal data processing in the US, we enter into the Data Processing Agreement and rely on Standard Contractual Clauses based on the European Commission Implementing Decision of June 4, 2021 (EU 2021/914). Furthermore, we carry out Transfer Impact Assessments to make sure that our business partners provides adequate protection of personal data. 
For processing taking place in Israel and the United Kingdom, we rely on the European Commission adequacy decision to transfer personal data.(Please see the adequacy decisions here: For Israel and the UK ) 
5. Your Rights 
According to data protection legislation, you may have a number of rights regarding the data we hold about you. If you wish to exercise any of these rights, please contact us at the contact details set out above. 
● a) The right to object to processing. You have the right to object to the processing of Personal Data concerning you based on art. 6 para. 1 e or f GDPR according to art. 21 GDPR. This also applies to direct marketing and related profiling. 
● b) The right of access. You have the right to access your data (if we process it) according to the provisions of art. 15 GDPR. This will enable you, for example, to check that we use your data in accordance with data protection law. 
● c) The right to be informed. You have the right to be provided with clear, transparent and easily understandable information about how we use your data and what your rights are according to the provisions of art. 13 and 14 GDPR. For this reason, we provide you with the information in this Data Protection Notice. 
● d) The right to restrict processing (blocking of data). You have the right to “block” or “restrict” the further use of your data according to the provisions of art. 18 GDPR. If processing is restricted, we may still store your data, but will not process it further. 
● e) The right to rectification. You have the right to have your data corrected if it is inaccurate or incomplete according to the provisions of art. 16 GDPR. You may request that we rectify any errors in the data we hold. 
● f) The right to erasure. This "right to be forgotten" enables you to request the deletion or removal of certain data that we have stored about you according to the provisions of art. 17 GDPR. This right is not absolute and only applies in certain circumstances. 
● g) The right to data portability. You have the right to obtain your Personal Data in an accessible and transferable format so that you can re-use it for your own purposes across different service providers according to the provisions of art. 20 GDPR. However, this is not an absolute right and there are exceptions. 
● h) The right to lodge a complaint. You have the right to lodge a complaint about the way we handle or process your information with a competent data protection authority according to art. 77 GDPR. 
● i) The right to withdraw consent. You have the right to withdraw any consent given to us (if we rely on the consent as a legal basis for the processing of certain data) at any time with effect for the future according to art. 7 GDPR. The legality of the
processing carried out on the basis of the consent prior to the withdrawal remains unaffected. 
Bloom does not use any of the automatic decision making or profiling described above. Specific Information on the right to object pursuant to art. 21 (4) GDPR 
You have the right to object, on grounds relating to your particular situation, at any time to processing of personal data concerning you which is based on points above of Article 6(1) GDPR, including profiling based on those provisions. We shall no longer process the personal data unless the controller demonstrates compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject or for the establishment, exercise or defence of legal claims. Where personal data are processed for direct marketing purposes, you shall have the right to object at any time to processing of personal data concerning you for such marketing, which includes profiling to the extent that it is related to such direct marketing. 
6. Security 
a) We use strong technologies and policies to ensure that your Personal Data we hold is appropriately protected. 
b) We take measures to protect your data from unauthorized access and unlawful processing, accidental loss, destruction and damage. 
c) Unfortunately, the transmission of data over the internet is not completely secure. Although we take steps to protect your Personal Data, we cannot guarantee the security of the information you transmit to us; any transmission is at your own risk. Once we have received your information, we will apply strict procedures and security features to prevent unauthorized access. 
7. Obligation To Provide Data 
Unless expressly stated at the time of collection, the provision of data is not required or obligatory. Such an obligation may result from legal requirements or contractual regulations. Failure to provide required personal data generally results in a contract not being able to be concluded and/or in us not being able to provide a requested service. Our employees will clarify on a case-by-case basis whether the provision of personal data is required by law or contract or necessary for the conclusion of a contract, whether there is an obligation to provide the personal data and what the consequences of not providing the personal data would be.
8. Changes To This Data Protection Notice 
We kindly ask you to inform yourself regularly about the contents of our data protection policy. We will adjust this Data Protection Notice as changes in our data processing practices make this necessary. We will inform you as soon as the changes require your cooperation (e.g. consent) or other individual notification. 
If we provide addresses and contact information of companies and organizations in this Data Protection Notice, we ask you to note that addresses may change over time and to verify the information before contacting us. 
9. Complaints 
If you should not be satisfied with our response to any complaint or believe our processing of your data does not comply with data protection legislation, you can make a complaint to: 
Andmekaitse inspektsioon 
Tatari 39, 10134 Tallinn 
Telephone: +372 627 4135 
E-Mail: info@aki.ee 
Bloom is headquartered in Tallinn, Estonia. You may also complain to another competent supervisory authority.
`

export default privacyText