const ru = {
  navbar: {
      X: 'X',
      OFFERS: 'ПРЕДЛОЖЕНИЯ',
      STORES: 'МАГАЗИНЫ',
      CONTACT: 'КОНТАКТЫ',
  },
  
  header: {
      hero1: 'Продуктовый шоппинг на',
      hero_highlight: 'новом уровне',
      hero2: '',
      hero_msg: 'Клиенты гибридных и тёмных магазинов Bloom могут посещать магазин, когда им угодно.',
      stores: 'Наш магазин',
      estore: 'Э-каталог',
  }, 
  offers: {
      hero: 'Наши предложения',
      hero_msg: 'Откройте для себя последние скидки и другие акции.',
  },
  platform: {
      hero: 'Скоро в продаже',
      hero_msg: 'Платформа для доставки продуктов нового поколения',
      msg: 'Наши цифровые системы находятся в стадии тестирования. Оставьте заявку, чтобы присоединиться к нашей тестовой команде и внести свой вклад своими отзывами!',
      join: 'Присоединиться',
  },
  stores: {
      hero: 'Наш магазин',
      hero_msg: 'Наш гибридный магазин обслуживает клиентов как оффлайн, так и онлайн через Bolt Food и Wolt.',
  },
  contact: {
      hero: 'Связаться с нами',
      name: 'Имя',
      email: 'Электронная почта',
      message: 'Сообщение',
      continue: 'Отправить'
  },
  footer: {
      about_us: 'О нас',
      privacy: 'Политика конфиденциальности',
      terms: 'Условия использования',
  },

  toast: {
      sending_message: 'Отправка сообщения..',
      message_sent: 'Ваше сообщение отправлено!'
  }
}

export default ru;
