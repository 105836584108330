import React from 'react'
import Box from './Box'
import image1 from '../images/bloom-nomme.jpg';
import image2 from '../images/bloom-tonismae.jpg';
import image3 from '../images/bloom-parnu.jpg';
import { useRecoilValue } from 'recoil';
import { languageState } from '../recoil/language/languageState';


function Services() {

  const l = useRecoilValue(languageState)

  return (
    <div id='services'>
        <div className='f-heading'>
            <h1>{l.stores.hero}</h1>
            <p>{l.stores.hero_msg}</p>
        </div>
        <div className='b-container'>
            <Box 
              image={image1} 
              alt='image2' 
              button='Nõmme'
              text='Raudtee tänaval asuv pimepood teenindamaks Nõmme keskuse elanikke.
                Pood on avatud iga päev kell 9 - 23.
                '
              link='https://www.google.com/maps/place/Bloom+N%C3%B5mme/@59.3867822,24.6847696,17z/data=!3m1!4b1!4m5!3m4!1s0x469295c9aa263507:0x3d15ef4bf7fe4f81!8m2!3d59.386751!4d24.6869776'
              
              />
            <Box 
              image={image2} 
              alt='image2' 
              button='Tõnismäe'
              text='See Vabaduse väljakust kiviviske kaugusel asuv hübriidpood,
                on meie kõige esimene esindus. Pood on avatud iga päev kell 8 - 23.
                '
              link='https://www.google.com/maps/place/Bloom+T%C3%B5nism%C3%A4gi/@59.4294359,24.7417857,16z/data=!4m9!1m2!2m1!1sbloom!3m5!1s0x0:0x930682bf74255aa6!8m2!3d59.4292308!4d24.7429649!15sCgVibG9vbVoHIgVibG9vbZIBC3N1cGVybWFya2V0'
              
              />
            <Box 
              image={image3} 
              alt='image3' 
              button='Pärnu'
              text='Hei Pärnakad! Avasime äsja esinduse Eesti suvepealinnas. Pood on avatud iga päev kell 10 - 21.
                '
              link='https://www.google.com/maps/place/Bloom+P%C3%A4rnu/@58.3846402,24.4999763,17z/data=!3m1!4b1!4m5!3m4!1s0x46ecfdfbffe70c2b:0xc26b660d7a2de065!8m2!3d58.3846374!4d24.502165'
              
              />
            

        </div>
    </div>
  )
}

export default Services