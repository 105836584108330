import React from 'react'
import Navbar from './Navbar';
import Powerslap from '../videos/1000AASTAT.mp4';
import Link from 'react-scroll/modules/components/Link';
import { useRecoilValue } from 'recoil';
import { languageState } from '../recoil/language/languageState';

function Header() {
    const l = useRecoilValue(languageState)



  return (
    <div id='main'>
        <Navbar/>
        <div className='name'>
            <h1>{l.header.hero1} <span>{l.header.hero_highlight}</span>{l.header.hero2}</h1>
            <p className='details'>{l.header.hero_msg}</p>
            <div className='header-btns'>
            <a href='https://www.google.com/maps/place/Bloom+T%C3%B5nism%C3%A4gi/@59.4294359,24.7417857,16z/data=!4m9!1m2!2m1!1sbloom!3m5!1s0x0:0x930682bf74255aa6!8m2!3d59.4292308!4d24.7429649!15sCgVibG9vbVoHIgVibG9vbZIBC3N1cGVybWFya2V0'className='cv-btn' smooth={true} duration={1000}>{l.header.stores}</a>
                <a href='https://wolt.com/et/est/tallinn/venue/bloompood' className='cv-btn1'>{l.header.estore}</a>
            </div>
        </div>
        <div className='dark-overlay'>
            <video autoPlay muted loop id="1000-years-video"
            
            
            >
                <source src={Powerslap} type="video/mp4" />
            </video>
        </div>
        
        <div className='arrow'></div>
    </div>
  )
}

export default Header