const en = {
    navbar: {
        X: 'X',
        OFFERS: 'OFFERINGS',
        STORES: 'STORES',
        CONTACT: 'CONTACT',

    },
    
    header: {
        hero1: 'Taking groceries to',
        hero_highlight: 'another lvl',
        hero2: '',
        hero_msg: "Bloom's hybrid- and darkstore customers visit the store whenever they feel like it.",
        stores: 'Our store',
        estore: 'E-catalogue',
    }, 
    offers: {
        hero: 'Our offerings',
        hero_msg: 'Discover the latest discounts & other promotions.',
        
    },
    platform: {
        hero: 'Coming soon',
        hero_msg: 'A new age grocery-delivery platform',
        msg: 'Our digital systems are currently in the testing phase. Submit a request to join our testing team and make a difference with your feedback!',
        join: 'Join',
    },
    stores: {
        hero: 'Our store',
        hero_msg: 'Our hybrid store serves clients both offline and online via Bolt Food and Wolt.',

    },
    contact: {
        hero: 'Get in touch',
        name: 'Name',
        email: 'Email',
        message: 'Message',
        continue: 'Submit'
    },
    footer: {
        about_us: 'About us',
        privacy: 'Privacy policy',
        terms: 'Terms & Conditions',
    },

    toast: {
        sending_message: 'Sending message..',
        message_sent: 'Your message was sent!'
    }
}

export default en

