import React from 'react';
import ReactDOM from 'react-dom';
import Privacy from './routes/privacy';
import Terms from './routes/terms';
import './index.css';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import App from './App';
import { RecoilRoot } from 'recoil';


ReactDOM.render(
    <RecoilRoot>
   <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        </Routes>
        
   </BrowserRouter>
   </RecoilRoot>
    
    
    , document.getElementById('root'));

