import React from 'react'
import privacyText from '../constants/legal/privacy'
import { useRecoilValue } from 'recoil';
import { languageState } from '../recoil/language/languageState';
function Privacy() {

    const l = useRecoilValue(languageState)
  return (
    <div className='f-heading'>
        <h1>{l.footer.privacy}</h1>
        <p>{privacyText}</p>
      </div>
  )
}

export default Privacy