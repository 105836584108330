import React from 'react';
import Header from './components/Header'
import Features from './components/Features';
import Services from './components/Services';
import Subscribe from './components/Subscribe';
import Footer from './components/Footer';
import { Toaster } from 'react-hot-toast';
import { useRecoilValue } from 'recoil';
import { languageState } from './recoil/language/languageState';

function App() {
  const l = useRecoilValue(languageState)
  return (
    <> <Toaster/>
    <div className="App">
     
      <Header/>
      <div className='f-heading'>
        <h1>{l.offers.hero}</h1>
        <p>{l.offers.hero_msg}</p>
      </div>
      <Features/>
        {/* <Services/> */}
      <Subscribe/>
      <Footer/>
     
      
    </div>
    </>
  );
}

export default App;
