import React from 'react'
import featureImage from '../images/bloom-client-showcase.png'
import { useRecoilValue } from 'recoil';
import { languageState } from '../recoil/language/languageState';

function Features() {
  const l = useRecoilValue(languageState)

  return (
    <div id='features'>
        <div className='features-model'>
            <img src={featureImage} alt='feature-image' />
        </div>
        <div className='features-text'>
            <h2>{l.platform.hero}</h2>
            <h3>{l.platform.hero_msg}</h3>
            <p>{l.platform.msg}</p>
            <a href='https://docs.google.com/forms/d/e/1FAIpQLSe0nPl4cn9ptFR2pW9QOgIpMZ321rQn3154kOQh6TFNWrGMrg/viewform?usp=sf_link'>{l.platform.join}</a>
        </div>
    </div>
  )
}

export default Features