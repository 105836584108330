
const ee = {
    navbar: {
        X: 'ESI',
        OFFERS: 'PAKKUMISED',
        STORES: 'POED',
        CONTACT: 'KONTAKT',

    },
    header: {
        hero1: 'Poendus',
        hero_highlight: 'uuel tasemel',
        hero2: '',
        hero_msg: 'Bloomi hübriidpoe klient käib poes siis kui tuju tuleb.',
        stores: 'Tõnismäe pood',
        estore: 'E-pood',
    },
    offers: {
        hero: 'Pakkumised',
        hero_msg: 'Tutvu meie aktiivsete kampaaniate & muude pakkumistega.',
        
    },
    platform: {
        hero: 'Peagi tulekul',
        hero_msg: 'Uue ajastu kojutellimise platvorm',
        msg: 'Meie digisüsteem on hetkel testimise faasis. Kui soovid osa saada platvormi valmimisest ning anda panuse vabatahtliku tagasisidestamisega, esita avaldus testrühmaga liitumiseks.',
        join: 'Liitu',
    },
    stores: {
        hero: 'Tõnismäe esindus',
        hero_msg: 'Meie hübriid- ning pimepoodidest saab vajaliku toidukraami kätte nii kohale tulles kui ka tellides läbi Bolt Food ja Wolt rakenduste.',

    },
    contact: {
        hero: 'Võta meiega ühendust',
        name: 'Nimi',
        email: 'Meiliaadress',
        message: 'Sõnum',
        continue: 'Jätka'
    },
    footer: {
        about_us: 'Meie kohta',
        privacy: 'Privaatsuspoliitika',
        terms: 'Kasutustingimused',
    },

    toast: {
        sending_message: 'Saadan..',
        message_sent: 'Sõnum saadetud!'
    }

}

export default ee 

