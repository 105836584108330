import React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil';
import { languageState } from '../recoil/language/languageState';


function Footer() {
    const l = useRecoilValue(languageState)


  return (
    <div className='footer' >
      
      <div className='footer-left'>
            <p>© Bloom Group OÜ 2024</p>
        </div>

        <div className='footer-right'>
        <a href='https://www.gaming-style.com/RushTeam/index.php?page=Game' className='cv-btn4'>{l.footer.about_us}</a>
        <Link   className='cv-btn4' to="/privacy">{l.footer.privacy}</Link>

            <a href='https://drive.google.com/file/d/1R_ueosZkMVOZNb8zTxD3LTnnsh4cbmnD/view?usp=sharing' className='cv-btn4'>{l.footer.terms}</a>

        </div>
           
    </div>
  )
}

export default Footer