import React from 'react';
import { Link } from 'react-scroll';
import { useRecoilState, useRecoilValue } from 'recoil';
import logo from '../images/logo1.png';
import { langState, languageState } from '../recoil/language/languageState';

function Navbar() {
    const [language, setLanguage] = useRecoilState(langState);
    const l = useRecoilValue(languageState);

    const handleLanguage = () => {
        console.log('Current language:', language);
        if (language === 'en') {
            setLanguage('ee');
        } else if (language === 'ee') {
            setLanguage('ru');
        } else {
            setLanguage('en');
        }
    };

    const getNextLanguageText = () => {
        switch (language) {
            case 'en':
                return 'Eesti keeles';
            case 'ee':
                return 'По-русски';
            case 'ru':
                return 'In English';
            default:
                return 'In English';
        }
    };

    return (
        <div>
            <nav>
                <Link to='main' className='logo'>
                    <img src={logo} alt='logo' />
                </Link>
                <input className='menu-btn' type='checkbox' id='menu-btn' />
                <label className='menu-icon' htmlFor='menu-btn'>
                    <span className='nav-icon'></span>
                </label>
                <ul className='menu'>
                    <li><Link to='main' activeClass='active' smooth={true} duration={1000}>{l.navbar.X}</Link></li>
                    {/* <li><Link to='features' smooth={true} duration={1000}>{l.navbar.OFFERS}</Link></li>
                    <li><Link to='services' smooth={true} duration={1000}>{l.navbar.STORES}</Link></li> */}
                    <li><Link to='subscribe' smooth={true} duration={1000}>{l.navbar.CONTACT}</Link></li>
                    {/* <li><Link to='#' >KONTAKT</Link></li> */}
                </ul>
                <div className='language-btn-container'>
                    <a onClick={handleLanguage} className='cv-btn2'>
                        {getNextLanguageText()}
                    </a>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
